body, html, #root, .app {
  width: 100%;
  height: 100%; }

.app {
  display: grid;
  grid-template-rows: 80px 1fr 80px; }

.non-grid-app {
  height: 100%; }

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word; }

#logo img {
  margin-top: 2px; }

.bold {
  font-weight: bold; }

.span-full {
  height: 100%;
  min-width: 0;
  min-height: 0; }

.card-group {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); }
  .card-group.front-page {
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); }
  .card-group .search-card.ui.card {
    margin-top: 0;
    margin-bottom: 0;
    overflow-wrap: break-word;
    width: 100% !important; }
    .card-group .search-card.ui.card .speeds {
      text-align: right;
      font-size: 14px; }
      .card-group .search-card.ui.card .speeds .range {
        font-size: 12px; }
    .card-group .search-card.ui.card .subTitle {
      display: grid;
      grid-template-columns: 1fr 1fr;
      font-size: 14px;
      color: #828282; }
    .card-group .search-card.ui.card .status-line {
      font-weight: normal;
      color: gray; }
      .card-group .search-card.ui.card .status-line span {
        padding-right: 5px; }

#chartSubTitle {
  margin-top: 2px; }

.footer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  align-items: center;
  background: linear-gradient(90deg, #5f3994 0%, #9b3593 100%); }
  .footer img {
    border-style: none;
    padding: 20px 0;
    height: 100%;
    justify-self: center; }

#menu {
  padding: 0 20px 0 20px;
  margin-bottom: 0;
  background: linear-gradient(90deg, #5f3994 0%, #9b3593 100%);
  border-radius: 0;
  color: white;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(400px, 1fr)); }
  #menu .header {
    font-family: 'Helvetica Neue';
    font-size: 30px;
    align-self: center;
    justify-self: left;
    grid-template-columns: auto 300px; }
  #menu .options {
    justify-self: right; }
  #menu .item, #menu .header a {
    color: white; }

.allSites {
  background-color: white;
  padding: 20%;
  margin-top: 0 !important; }
  .allSites .header {
    display: grid;
    grid-template-columns: 1fr 2fr; }
    .allSites .header .name {
      align-self: center; }
    .allSites .header .data {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: right;
      grid-gap: 8px; }
  .allSites .status-bar-container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); }
    .allSites .status-bar-container .chartTitle {
      font-size: 16px;
      margin-bottom: 2px;
      grid-template-columns: 1fr 2fr; }
    .allSites .status-bar-container .speeds {
      text-align: right;
      font-size: 16px;
      font-weight: bold; }
    .allSites .status-bar-container .speeds .range {
      font-size: 14px;
      font-weight: normal; }

.metrics-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  font-family: 'Helvetica Neue';
  justify-items: center;
  align-content: center;
  height: 200px; }
  .metrics-container .metrics-item {
    height: max-content;
    text-align: center; }
    .metrics-container .metrics-item h2 {
      font-size: 35pt; }
    .metrics-container .metrics-item p {
      font-size: 14pt; }

.metrics-footer {
  display: grid;
  grid-template-columns: 100px 1fr; }
  .metrics-footer .label {
    height: 25px;
    align-self: baseline; }

.pop-up {
  position: absolute;
  z-index: 100;
  border: 1px solid lightgray;
  color: black;
  background: white;
  font-size: 12px;
  font-weight: normal; }

.responsive-loader {
  position: relative !important;
  top: unset;
  padding: 20px; }

.chart-container {
  display: grid;
  grid-template-rows: 250px 250px 250px; }

.rum {
  background-color: #f2efef; }
  .rum .main {
    padding: 20px;
    height: 100%; }
  .rum .nav-container {
    background-color: white;
    padding: 20px; }

.header-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  background-color: white; }
  .header-container div.link {
    text-align: right; }
  .header-container div.link a,
  .header-container .brand-table-header div.link a,
  .header-container ReactTable a {
    color: #652c90; }

.header-cb {
  padding-bottom: 10px; }

.header-cb span {
  margin-right: 20px; }

.header-cb > label {
  margin-right: 20px;
  cursor: pointer; }

.ReactTable.rum-table .rt-th {
  overflow: initial; }

.ReactTable.adopt-table .rt-th {
  overflow: initial;
  white-space: initial; }

.ReactTable.pl-table .rt-th {
  overflow: initial; }

.ReactTable a {
  color: #652c90; }

.brand-table-header {
  display: grid;
  grid-template-columns: 3fr 1fr; }
  .brand-table-header div {
    text-align: right;
    padding-bottom: 5px; }
  .brand-table-header span {
    text-align: right; }

.header-grid {
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 3fr 1fr; }
  .header-grid div.link {
    text-align: right;
    color: #652c90; }
    .header-grid div.link a {
      padding: 5px;
      color: #652c90;
      text-decoration: underline; }
      .header-grid div.link a.disabled {
        color: black;
        text-decoration: unset; }
  .header-grid span {
    text-align: right; }
  .header-grid h3 {
    padding-bottom: 7px; }

.sub-header-rum-table {
  display: grid;
  grid-template-columns: 3fr 1fr; }
  .sub-header-rum-table span {
    text-align: right; }

.rum-summary-table form {
  display: grid;
  grid-template-columns: min-content min-content max-content max-content max-content max-content max-content max-content; }
  .rum-summary-table form div.field {
    padding-right: 25px; }

.rum-summary-table form span {
  display: grid;
  grid-template-columns: 320px repeat(auto-fill, 130px); }

.adoption-data-table {
  height: 100%;
  display: flex;
  flex-direction: column; }

.adoption-data-table__header-cell {
  overflow: hidden;
  text-overflow: ellipsis; }
  .adoption-data-table__header-cell--orange {
    color: orange;
    background: rgba(255, 238, 0, 0.05); }

.adoption-data-table__cell {
  padding: 2px;
  box-sizing: border-box;
  text-align: center; }
  .adoption-data-table__cell--green {
    color: green;
    background: rgba(0, 255, 0, 0.05); }
  .adoption-data-table__cell--red {
    color: red;
    background: rgba(255, 0, 0, 0.05); }
  .adoption-data-table__cell--amber {
    color: orange;
    background: rgba(255, 165, 0, 0.05); }
  .adoption-data-table__cell--yellow {
    color: black;
    background: #f0f0b4; }

.adoption-data-table__wrapper {
  padding: 5px 20px;
  min-height: 0;
  min-width: 0; }
  .adoption-data-table__wrapper[data-expanded] {
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10; }

.adoption-data-table__container {
  height: 100%;
  display: flex;
  flex-direction: column; }

.adoption-data-table__headers h1, .adoption-data-table__headers h2 {
  margin: 0; }

.adoption-data-table__headers h2 {
  margin-top: 5px;
  font-size: 17px;
  color: red;
  font-weight: normal; }

.adoption-data-table__header-cb {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.toggle-button-container {
  justify-content: right;
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fit, minmax(40px, 120px)); }

.filter-group .button {
  background: white;
  border: none;
  color: #652c90;
  text-decoration: underline; }
  .filter-group .button.active {
    color: black;
    text-decoration: unset;
    font-weight: bold; }
  .filter-group .button:disabled, .filter-group .button[disabled] {
    color: grey;
    text-decoration: none; }

.radio-filter {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 130px)); }
  .radio-filter .radio {
    width: fit-content; }
    .radio-filter .radio grid label::after {
      background-color: #652c90 !important; }
    .radio-filter .radio label::before {
      border: #652c90 1px solid !important; }

.button-attached-right {
  border-radius: 4px;
  margin: 0 0 1rem 0;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6); }
  .button-attached-right:hover {
    background-color: #cacbcd; }
  .button-attached-right:focus {
    background-color: #cacbcd;
    outline: unset; }

.spanning-component {
  height: 100%; }
  .spanning-component.dual {
    justify-items: center;
    display: grid;
    grid-template-rows: 1fr 1fr;
    -ms-flex-align: center;
    align-items: flex-end; }
    .spanning-component.dual a {
      align-self: flex-start; }

.date-range__container {
  display: flex;
  align-items: center;
  padding: 5px 0; }

.date-range__download-button {
  margin-left: 10px; }

.home-page {
  display: grid;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  padding: 20px; }

.time {
  grid-column: span 4;
  justify-self: right;
  font-weight: 700;
  font-size: 1.28571429em;
  padding: 15px; }

.home-metrics {
  grid-column: span 2;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 50px; }
  .home-metrics.last-child {
    grid-column: 2/span 2; }
  .home-metrics .button {
    margin-bottom: 14px !important;
    border-radius: 4px !important; }

.login {
  width: 100%;
  height: 93vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #ecf0f3;
  color: #a0a5a8; }

.login-main {
  position: relative;
  width: 400px;
  min-width: 400px;
  min-height: 600px;
  height: 600px;
  padding: 25px;
  background-color: #ecf0f3;
  box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
  border-radius: 12px;
  overflow: hidden; }

.title {
  font-size: 34px;
  font-weight: 700;
  line-height: 3;
  color: #181818; }

.description {
  font-size: 14px;
  letter-spacing: 0.25px;
  text-align: center;
  line-height: 1.6; }

.button {
  width: 199px;
  height: 50px;
  border-radius: 25px;
  margin-top: 50px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.15px;
  background-color: #4b70e2;
  color: #f9f9f9;
  box-shadow: 8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9;
  border: none;
  outline: none; }

.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 400px;
  padding: 50px;
  z-index: 200;
  transition: 1.25s;
  background-color: #ecf0f3;
  overflow: hidden;
  box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9; }

.switch__circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-color: #ecf0f3;
  box-shadow: inset 8px 8px 12px #d1d9e6, inset -8px -8px 12px #f9f9f9;
  bottom: -60%;
  left: -60%;
  transition: 1.25s; }

.switch__circle--t {
  top: -30%;
  left: 60%;
  width: 300px;
  height: 300px; }

.switch__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 400px;
  padding: 50px 55px;
  transition: 1.25s; }

.switch__button {
  cursor: pointer; }
  .switch__button:hover {
    box-shadow: 6px 6px 10px #d1d9e6, -6px -6px 10px #f9f9f9;
    transform: scale(0.985);
    transition: 0.25s; }
  .switch__button:active, .switch__button:focus {
    box-shadow: 2px 2px 6px #d1d9e6, -2px -2px 6px #f9f9f9;
    transform: scale(0.97);
    transition: 0.25s; }

.login-in-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 30px;
  flex-direction: column; }

.container {
  padding-top: 10px;
  margin-bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  overflow-y: scroll;
  max-height: 220px;
  box-shadow: inset 0px 11px 8px -10px #ccc, inset 0px -11px 8px -10px #ccc; }
  .container-list {
    padding: 0 10px 10px 0; }
    .container-list-highlight {
      font-weight: bold; }
