/* Modal.css */

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.json-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.json-table th, .json-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.json-table th {
  background-color: #f2f2f2;
  text-align: left;
}
  