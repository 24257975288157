.formDiv {
    display: flex;
    justify-content: center;
    margin-top: 2%;
}

.leftMargin {
    margin-left: 2%;
}

.submitButtonMargin {
    margin-left: 5%;
}

.topMargin {
    margin-top: 5%;
}

.fullWidth {
    width: -webkit-fill-available;
}

.textCentre {
    text-align: center;
}

.reportIdButton {
    border: 0;
    padding: 0;
    width: 100%;
    background-color: transparent;
}